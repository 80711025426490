import React from 'react';
import ReactDOM from 'react-dom';
import Application from '@webplots/core';
import theme from './theme';
import './styles.css';

const rootEl = document.getElementById('root');

const embedMode = (window as any).isEmbedded as boolean;

ReactDOM.render(<Application theme={theme(embedMode)} />, rootEl);
